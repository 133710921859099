import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { createRouter, createWebHistory } from 'vue-router';
import { db } from '../firebase';
import Account from '../views/AccountDetails.vue';
import AppAdmin from '../views/AppAdmin.vue';
import CompanyAdmin from '../views/CompanyAdmin.vue';
import CompanyDetails from '../views/CompanyDetails.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import Home from '../views/HomeContainer.vue';
import Login from '../views/LoginView.vue';
import Signup from '../views/SignupView.vue';

const routes = [
  //  Pages that require authentication
  { path: '/', name: 'Home', component: Home, meta: { requiresAuth: true } },
  {
    path: '/company/:companyDomain',
    name: 'CompanyDetails',
    component: CompanyDetails,
    meta: { requiresAuth: true },
  },
  { path: '/account', name: 'Account', component: Account, meta: { requiresAuth: true } },
  // All pages below do not require auth
  { path: '/login', name: 'Login', component: Login },
  { path: '/signup', name: 'Signup', component: Signup },
  { path: '/forgot-password', name: 'ForgotPassword', component: ForgotPassword },
  // All pages below require admin privilges
  {
    path: '/admin',
    name: 'AppAdmin',
    component: AppAdmin,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/company/:companyDomain',
    name: 'CompanyAdmin',
    component: CompanyAdmin,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation guard to protect routes
router.beforeEach((to, from, next) => {
  const auth = getAuth();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);

  if (requiresAuth) {
    onAuthStateChanged(auth, async user => {
      if (user) {
        if (requiresAdmin) {
          try {
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            if (userDoc.exists() && userDoc.data().role === 'admin') {
              next(); // User has admin role, allow access
            } else {
              next('/'); // User doesn't have admin role, redirect to home
            }
          } catch (error) {
            console.error('Error checking user role:', error);
            next('/'); // Redirect to home if there's an error
          }
        } else {
          next(); // No admin role required, proceed as normal
        }
      } else {
        next('/login'); // No user is authenticated, redirect to login
      }
    });
  } else {
    next(); // No authentication required, proceed as normal
  }
});

export default router;
