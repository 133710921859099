<template>
    <div class="mb-4 card">
      <div class="card-body">
        <div v-if="testResults && testResults.emailResults">
            <div class="row">
                <div class="col">   
                    <h4 class="card-title">Email Security</h4>
                </div>
                <div class="col">
                    <h3 class="float-end">
                        <span class="badge bg-secondary">--%</span>
                    </h3>
                </div>
            </div>
            
            <div class="row px-3">
                <table class="table ">
                    <tbody>
                    <!-- SPF Results -->
                     <!-- SPF 1 -->
                    <tr v-if="testResults.emailResults.spfRecord">
                        <th scope="row">
                            <i v-if="testResults.emailResults.spfRecord.spf1result == 1" class="h3 text-success bi bi-check-circle-fill"></i>
                            <i v-if="testResults.emailResults.spfRecord.spf1result == 3" class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                            
                        </th>
                        <td class="lead">Sender Policy Framwork (SPF) record exists</td>
                        <td>
                            <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#spfTest1" role="button" aria-expanded="false" aria-controls="spfTest1"></i>
                        </td>
                    </tr>
                    <tr class="collapse multi-collapse" id="spfTest1">
                        <td colspan="3"> 
                            <p class="blockquote-footer mt-2 ">{{ testResults.emailResults.spfRecord.spf1info }}</p>  
                            <p class="text-muted ms-3">
                                An SPF (Sender Policy Framework) record is essential for protecting your email domain from being used in email spoofing attacks. By specifying which mail servers are authorised to send email on behalf of your domain, an SPF record helps prevent malicious actors from sending unauthorised emails that appear to come from your domain. This not only improves your email deliverability by reducing the likelihood of your messages being marked as spam but also enhances your domain's reputation and protects your recipients from phishing and other fraudulent emails.
                            </p>
                        </td>
                    </tr>
                    <!-- SPF 2 -->
                    <tr>
                        <th scope="row">
                            <i v-if="testResults.emailResults.spfRecord.spf2result == 1" class="h3 text-success bi bi-check-circle-fill"></i>
                            <i v-if="testResults.emailResults.spfRecord.spf2result == 3" class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                        </th>
                        <td class="lead">SPF deny all flag present</td>
                        <td>
                            <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#spfTest2" role="button" aria-expanded="false" aria-controls="spfTest2"></i>
                        </td>
                    </tr>
                    <tr class="collapse multi-collapse" id="spfTest2">
                        <td colspan="3">
                            <p class="blockquote-footer mt-2">{{ testResults.emailResults.spfRecord.spf2info }}</p>   
                            <p class="text-muted ms-3">
                                An SPF (Sender Policy Framework) record with a deny flag (typically indicated by "-all") is crucial for enhancing your email security. This flag explicitly tells receiving mail servers to reject any emails that are not sent from your authorised mail servers, thereby preventing unauthorised use of your domain in email spoofing attacks. By implementing an SPF record with a deny flag, you significantly reduce the risk of phishing and other fraudulent emails being sent from your domain, protect your domain's reputation, and improve the overall trustworthiness of your email communications.
                            </p>
                        </td>
                    </tr>
                    <!-- SPF 3 -->
                    <tr>
                    <th scope="row">
                        <i v-if="testResults.emailResults.spfRecord.spf2result == 1" class="h3 text-success bi bi-check-circle-fill"></i>
                        <i v-if="testResults.emailResults.spfRecord.spf2result == 2" class="h3 text-success bi bi-exclamation-triangle-fill"></i>
                        <i v-if="testResults.emailResults.spfRecord.spf2result == 3" class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                    </th>
                    <td class="lead">Reasonable number of mailservers listed within SPF Record</td>
                    <td>
                        <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#spfTest3" role="button" aria-expanded="false" aria-controls="spfTest3"></i>
                    </td>
                    </tr>
                    <tr class="collapse multi-collapse" id="spfTest3">
                        <td colspan="3">   
                            <p class="blockquote-footer mt-2">{{ testResults.emailResults.spfRecord.spf3info }}</p> 
                            <p class="text-muted ms-3">
                                Having a minimal amount of mail servers (less than ten) listed within your SPF (Sender Policy Framework) record is crucial for maintaining email security and manageability. This reduces the risk of email spoofing, enhances deliverability, simplifies management, and protects your domain's reputation. However, listing more than one server ensures redundancy and reliability, providing a failover mechanism for continuous email delivery. This balanced approach optimises both security and reliability in your email operations.
                            </p>
                        </td>
                    </tr>
                    <!-- DMARC Results -->
                    <!-- DMARC 1 -->
                    <tr>
                    <th scope="row">
                        <i v-if="testResults.emailResults.dmarcRecord.dmarc1result == 1" class="h3 text-success bi bi-check-circle-fill"></i>
                        <i v-if="testResults.emailResults.dmarcRecord.dmarc1result == 3" class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                    </th>
                    <td class="lead">DMARC record exists</td>
                    <td>
                        <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#dmarcTest1" role="button" aria-expanded="false" aria-controls="dmarcTest1"></i>
                    </td>
                    </tr>
                    <tr class="collapse multi-collapse" id="dmarcTest1">
                        <td colspan="3">   
                            <p class="blockquote-footer mt-2">{{ testResults.emailResults.dmarcRecord.dmarc1info }}</p> 
                            <p class="text-muted ms-3">
                                A DMARC (Domain-based Message Authentication, Reporting, and Conformance) record is essential for enhancing your email security. By specifying how receiving mail servers should handle emails that fail SPF or DKIM (DomainKeys Identified Mail) checks, DMARC helps prevent unauthorised use of your domain in phishing and email spoofing attacks. It also provides detailed reports on email activity, allowing you to monitor and improve your email authentication practices. Implementing a DMARC record protects your domain's reputation, increases email deliverability, and enhances the overall trustworthiness of your email communications.
                            </p>
                        </td>
                    </tr>
                    <!-- DMARC 2 -->
                    <tr>
                    <th scope="row">
                        <i v-if="testResults.emailResults.dmarcRecord.dmarc2result == 1" class="h3 text-success bi bi-check-circle-fill"></i>
                        <i v-if="testResults.emailResults.dmarcRecord.dmarc2result == 3" class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                    </th>
                    <td class="lead">DMARC record configured to quarantine or reject spoofed emails</td>
                    <td>
                        <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#dmarcTest2" role="button" aria-expanded="false" aria-controls="dmarcTest2"></i>
                    </td>
                    </tr>
                    <tr class="collapse multi-collapse" id="dmarcTest2">
                        <td colspan="3">   
                            <p class="blockquote-footer mt-2">{{ testResults.emailResults.dmarcRecord.dmarc2info }}</p> 
                            <p class="text-muted ms-3">
                                Configuring your DMARC (Domain-based Message Authentication, Reporting, and Conformance) record to quarantine or reject spoofed emails is crucial for robust email security. By doing so, you ensure that fraudulent emails failing SPF or DKIM checks are either placed in the recipient's spam folder or rejected outright, preventing them from reaching the inbox. This reduces the risk of phishing and other malicious activities, protects your domain's reputation, and enhances the overall trust and deliverability of your legitimate emails.
                            </p>
                        </td>
                    </tr>
                    <!-- DMARC 3 -->
                    <tr>
                    <th scope="row">
                        <i v-if="testResults.emailResults.dmarcRecord.dmarc3result == 1" class="h3 text-success bi bi-check-circle-fill"></i>
                        <i v-if="testResults.emailResults.dmarcRecord.dmarc3result == 3" class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                    </th>
                    <td class="lead">DMARC aggregate reports configured</td>
                    <td>
                        <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#dmarcTest3" role="button" aria-expanded="false" aria-controls="dmarcTest3"></i>
                    </td>
                    </tr>
                    <tr class="collapse multi-collapse" id="dmarcTest3">
                        <td colspan="3">   
                            <p class="blockquote-footer mt-2">{{ testResults.emailResults.dmarcRecord.dmarc3info }}</p> 
                            <p class="text-muted ms-3">
                                Configuring DMARC aggregate reports is essential for maintaining robust email security. These reports provide detailed insights into how your emails are being processed and whether they pass SPF and DKIM checks. By analysing this data, you can identify and address potential authentication issues, monitor for unauthorised use of your domain, and improve your email authentication policies. This proactive approach helps protect your domain's reputation, ensures the integrity of your email communications, and enhances overall email deliverability.
                            </p>
                        </td>
                    </tr>
                    <!-- Unregistered Domain in MX Record -->
                    <tr>
                    <th scope="row">
                        <i class="h3 text-secondary bi bi-dash-circle-fill"></i>
                       
                    </th>
                    <td class="lead">All domains within MX record are registered</td>
                    <td>
                        <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#registermxTest1" role="button" aria-expanded="false" aria-controls="registermxTest1"></i>
                    </td>
                    </tr>
                    <tr class="collapse multi-collapse" id="registermxTest1">
                        <td colspan="3">   
                            <p class="blockquote-footer mt-2">Test not carried out.</p> 
                            <p class="text-muted ms-3">
                                Having unregistered domains within your MX (Mail Exchange) records is a serious security risk. Unregistered domains can be easily registered by malicious actors, who can then intercept, read, or manipulate your email traffic. This can lead to data breaches, phishing attacks, and loss of sensitive information. Additionally, it can harm your organisation's reputation and disrupt communication. Ensuring that all domains in your MX records are properly registered and controlled by your organisation is crucial for maintaining email security and protecting against potential threats.
                            </p>
                        </td>
                    </tr>
                     <!-- Unregistered Domain in MX Record -->
                     <tr>
                    <th scope="row">
                        <i class="h3 text-secondary bi bi-dash-circle-fill"></i>
                       
                    </th>
                    <td class="lead">Email server connecting using TLS</td>
                    <td>
                        <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#emailTest1" role="button" aria-expanded="false" aria-controls="emailTest1"></i>
                    </td>
                    </tr>
                    <tr class="collapse multi-collapse" id="emailTest1">
                        <td colspan="3">   
                            <p class="blockquote-footer mt-2">Test not carried out.</p> 
                            <p class="text-muted ms-3">
                                email TLS blurb
                            </p>
                        </td>
                    </tr>
                     <!-- Unregistered Domain in MX Record -->
                     <tr>
                    <th scope="row">
                        <i class="h3 text-secondary bi bi-dash-circle-fill"></i>
                       
                    </th>
                    <td class="lead">Mail server does not look to be an open relay</td>
                    <td>
                        <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#registermxTest1" role="button" aria-expanded="false" aria-controls="registermxTest1"></i>
                    </td>
                    </tr>
                    <tr class="collapse multi-collapse" id="registermxTest1">
                        <td colspan="3">   
                            <p class="blockquote-footer mt-2">Test not carried out.</p> 
                            <p class="text-muted ms-3">
                                Open relay blurb....
                            </p>
                        </td>
                    </tr>
                     <!-- Unregistered Domain in MX Record -->
                     <tr>
                    <th scope="row">
                        <i class="h3 text-secondary bi bi-dash-circle-fill"></i>
                       
                    </th>
                    <td class="lead">SMTP Enumeration protocols disabled</td>
                    <td>
                        <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#registermxTest1" role="button" aria-expanded="false" aria-controls="registermxTest1"></i>
                    </td>
                    </tr>
                    <tr class="collapse multi-collapse" id="registermxTest1">
                        <td colspan="3">   
                            <p class="blockquote-footer mt-2">Test not carried out.</p> 
                            <p class="text-muted ms-3">
                                Having unregistered domains within your MX (Mail Exchange) records is a serious security risk. Unregistered domains can be easily registered by malicious actors, who can then intercept, read, or manipulate your email traffic. This can lead to data breaches, phishing attacks, and loss of sensitive information. Additionally, it can harm your organisation's reputation and disrupt communication. Ensuring that all domains in your MX records are properly registered and controlled by your organisation is crucial for maintaining email security and protecting against potential threats.
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div> 
        </div>
        <div v-else class="p-5 m-5 d-flex justify-content-center">
            <div class=" spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>   
    </div>
</div>

</template>




<script>
export default {
  props: ['testResults'],
};
</script>

<style>
</style>